import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { useNavigate } from "react-router-dom";
import AxiosInstance from '../Axios';
import { transliterate } from 'transliteration';

const CategoriesForm = (props) => {
    // console.log("CategoriesForm props", props);

    let data = props.data;

    const [name, setName] = useState((data != undefined) ? data.name : '');
    const [slug, setSlug] = useState((data != undefined) ? data.slug : '');
    const [status, setStatus] = useState((data != undefined && data.status != "") ? data.status : 'active');
    const [type, setType] = useState((data != undefined) ? data.type : 'category');
    const [parent, setParent] = useState((data != undefined) ? data.parent : '');
    const [sort, setSort] = useState((data != undefined && data?.sort) ? data.sort : 0);
    const [main, setMain] = useState((data?.main != undefined) ? data.main : (props.parent !== undefined ? false : true));

    // const [Props, setProps] = useState(props);
    const editorRef = useRef(null);
    // Ключ для Editor
    const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
    // Текст для popup
    const popupTextCategoryPostSuccess = "Категорію створено";
    const popupTextSubCategoryPostSuccess = "Підкатегорію створено";
    const popupTextCategoryPutSuccess = "Категорію оновлено";
    const popupTextError = "Помилка запиту";

    useEffect(() => {
        setName((data != undefined) ? data.name : '');
        setSlug((data != undefined) ? data.slug : '');
        setStatus((data != undefined && data.status != "") ? data.status : 'active');
        setType((data != undefined) ? data.type : 'category');
        setParent((data != undefined) ? data.parent : '');
        setSort((data != undefined && data?.sort) ? data.sort : 0);
        setMain((data?.main != undefined) ? data.main : (props.parent !== undefined ? false : true));
    }, [data, props]);

    let navigate = useNavigate();

    const handleSubmit = event => {
        event.preventDefault();
        let slugT;
        if (editorRef.current) {
            // console.log(editorRef.current.getContent());
            if (slug == '')
                slugT = transliterate(name).replace(' ', '');
            else
                slugT = transliterate(slug).replace(' ', '');

            let article = {
                name: name,
                slug: slugT,
                description: editorRef.current.getContent(),
                status: status,
                // parent: (props.parent != undefined)? `/api/categories/${parent}`: null,
                main: main,
                type: type,
                sort: parseInt(sort),
            };

            if (data != undefined && props.type === "sub") {
                article['parent'] = `/api/categories/${data.parent.id}`;
            } else if (props.type === "sub") {
                article['parent'] = (props.parent != undefined) ? `/api/categories/${props.parent}` : null;
            }

            // console.log('article', article);

            if (data != undefined) {
                AxiosInstance.put('/categories/' + data.id, article).then((response) => {
                    // console.log('categories put response', response);
                    if (response.status === 200) {
                        // Передати наверх назву категорії
                        props.setMyData(response.data);

                        // Popup про успішні зміни
                        props.toastifySuccess(popupTextCategoryPutSuccess);
                    } else {
                        // console.log("Помилка запиту");
                        props.toastifyWarning(popupTextError);
                    }
                });
            } else {
                AxiosInstance.post('/categories', article).then((response) => {
                    // console.log('categories post response', response);
                    if (response.status == 201) {
                        if (props.type == 'page') {
                            // Popup про успішні зміни
                            props.toastifySuccess(popupTextCategoryPostSuccess);
                            return navigate("/category/update/" + response.data.id);
                        }
                        if (props.type == 'sub') {
                            // Popup про успішні зміни
                            props.toastifySuccess(popupTextSubCategoryPostSuccess);
                            props.actionShowModal(false);
                        }
                    } else {
                        // console.log("Помилка запиту");
                        props.toastifyWarning(popupTextError);
                    }
                    return response;
                });
            }
        }
    };

    return (
        <>
            <CForm onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
                            <CFormInput type="text" id="exampleFormControlInput2" value={slug} onChange={(e) => setSlug(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
                            <select className="form-select" value={status} onChange={(e) => setStatus(e.target.value)} >
                                <option value="active">Активно</option>
                                <option value="disabled">Не активно</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Тип</CFormLabel>
                            <select className="form-select" defaultValue={type} onChange={(e) => setType(e.target.value)} >
                                <option value="category">Категорія</option>
                                <option value="taxonomy">Таксономія</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div className="mb-3">
                            <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                            <CFormInput type="number" step={1} min={0} id="exampleFormControlInput2" value={sort} onChange={(e) => setSort(e.target.value)} autoComplete="off" />
                        </div>
                    </div>
                </div>

                <div className="mb-3">
                    <Editor
                        apiKey={apiKey}
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={(data != undefined) ? data.description : ''}
                        init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                    />
                </div>
                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
};

export default CategoriesForm;