import React, { useRef, useState, useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import SubCategories from 'src/components/category/SubCategories';
import CategoriesProducts from 'src/components/category/CategoriesProducts';
import CategoriesForm from 'src/components/category/Form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import CategoryMedia from 'src/components/category/CategoryMedia';
import { CButton } from '@coreui/react';
import scrollToTop from 'src/components/store/scrollToTop';

const urlApi = '/categories';
const urlRouter = '/category';

export async function LoaderCategoryUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`, {
      params: {
        'g[0]': 'cat:read',
        'g[1]': 'cat:categories',
        'g[2]': 'cat:media',
        'g[3]': 'cat:parent',
      }
    }).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}


const CategoryForm = () => {

  const { data } = useLoaderData();
  let navigate = useNavigate();
  const [myData, setMyData] = useState((data != undefined) ? data : false);
  const [parentId, setParentId] = useState(null);
  const popup = MyToastify(); // Викликає popup з власного компонента

  // Заповнити id батьківської категорії
  useEffect(() => {
    if (myData?.parent) {
      setParentId(myData.parent.id);
    }
    // else {
    //   setParentId(null);
    // };
  }, [myData]);

  useEffect(() => {
    scrollToTop();
    setMyData(data);
  }, [data]);

  return (
    <div className="mb-3">
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Назва категорії та зображення */}
      {
        (myData != false && myData != undefined) &&
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-3">{myData.name}</h1>
            {
              (parentId && !data.main) &&
              <CButton color="secondary" onClick={() => navigate(`${urlRouter}/update/${parentId}`)} title="Перейти до батьківської категорії">
                До батьк. категорії
              </CButton>
            }
          </div>
          <CategoryMedia
            myData={myData} urlApi={urlApi}
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning}
          />
        </>
      }

      {/* Фарма: назва, синонім, статус, тип та опис */}
      <CategoriesForm data={myData} setMyData={setMyData} type='page' toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />

      {/* Підкатегорії: додавання підкатегорій та список */}
      {
        (myData != undefined) &&
        <>
          <hr className="mb-4" />
          <SubCategories categories={myData.categories} parent={myData.id} parent_name={myData.name} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }

      {/* Товари: додавання товарів та список */}
      {
        (myData != undefined) &&
        <>
          <hr className="mb-4" />
          <CategoriesProducts category_id={myData.id} toastifySuccess={popup.toastifySuccess} />
        </>
      }
    </div >
  );
};

export default CategoryForm;
