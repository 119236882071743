import React, { useEffect, useState } from 'react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CFormCheck, CNav, CNavItem, CNavLink } from '@coreui/react';

// Вкладка "Ціна"
const ProductPrice = (props) => {

    const [price, setPrice] = useState(props?.MyData?.price ?? '');
    // const [discounts, setDiscounts] = useState((props.data != undefined) ? props.data.discounts : '');
    const [oldPrice, setOldPrice] = useState(props?.MyData?.oldPrice ?? 0);
    const [newOldPrice, setNewOldPrice] = useState(props?.MyData?.price ?? 0);
    const [isSalle, setIsSalle] = useState(props?.MyData?.isSalle ?? false);
    const [isFreeShipping, setIsFreeShipping] = useState(props?.MyData?.isFreeShipping ?? false); // Безкоштовна доставка
    const [isDisabledBtnSubmit, setIsDisabledBtnSubmit] = useState(true);

    const data_response = {
        price: parseFloat(price),
        isSalle: isSalle,
        oldPrice: newOldPrice,
        // discounts: parseFloat(discounts),
        isFreeShipping: isFreeShipping,
        active: 'on',
        //     name: name,
        //     description: editorRef.current.getContent(),
        //     // description: description,
        //     code1c: code1c,
        //     barcode: barcode,
        //     status: status,
        //     article: article,
        //     nds: parseFloat(nds),
        //     packaging: parseFloat(packaging),
        //     measurement_unit: `/api/measurment_units/${measurmentUnit.value}`,
    };

    useEffect(() => {
        if (props.MyData) {
            setOldPrice(props.MyData.oldPrice);
            setPrice(props.MyData.price);
        }
    }, [props.MyData]);

    // Вибирати стару ціну
    useEffect(() => {
        if (props.MyData != undefined) {
            if (props.MyData.price === price) {
                setNewOldPrice(props.MyData.oldPrice);
            } else {
                setNewOldPrice(props.MyData.price);
            }
        }
    }, [oldPrice, price]);

    // Функція для оновлення даних
    const submitData = () => {
        setIsDisabledBtnSubmit(true);

        props.handleSubmit(event, data_response);
    };

    // Відслідковувати блокування кнопки оновлення даних
    useEffect(() => {
        if (props?.MyData?.price && props.MyData.price == price && props.MyData.oldPrice == oldPrice && props.MyData.isSalle === isSalle && props.MyData.isFreeShipping === isFreeShipping) {
            setIsDisabledBtnSubmit(true);
        } else {
            setIsDisabledBtnSubmit(false);
        };
    }, [props.MyData, price, oldPrice, isSalle, isFreeShipping])

    return (
        <div>
            <CForm>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput11" label="Безкоштовна доставка" checked={isFreeShipping} onChange={() => setIsFreeShipping(!isFreeShipping)} />
                </div>
                <div className="mb-3">
                    <CFormCheck id="exampleFormControlInput11" label="Акція" checked={isSalle} onChange={() => setIsSalle(!isSalle)} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Попередня ціна</CFormLabel>
                    <CFormInput type="number" id="exampleFormControlInput7" value={oldPrice == null ? 0 : oldPrice} disabled={true} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Актуальна ціна</CFormLabel>
                    <CFormInput type="number" id="exampleFormControlInput6" value={price == null ? "" : price} onChange={(e) => setPrice(e.target.value)} autoComplete="off" />
                </div>
                {/* <CButton color="success" onClick={() => props.handleSubmit(event, data_response)}>Зберегти</CButton> */}
                <CButton color="success" onClick={() => submitData(event)} disabled={isDisabledBtnSubmit}>Зберегти</CButton>
            </CForm>
        </div>
    );
};

export default ProductPrice;